import React from "react";
import Grid from "@mui/material/Grid";
import { makeStyles } from "@mui/styles";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import PhoneIcon from "@mui/icons-material/Phone";
import { FormattedMessage } from "react-intl";

const useStyles = makeStyles((theme) => {
  return {
    outer: {
      width: "1000px",
      maxWidth: "100%",
      backgroundColor: "#F5F5F5",
      borderRadius: "30px",
      padding: "0rem 1rem",
      margin: "1rem 0rem 0rem",
    },
    gallery: {
      display: "flex",
      flexDirection: "row",
      flexWrap: "wrap",
      alignItems: "flex-start",
    },
    galleryItem: {
      padding: "1rem 1% 1rem",
      display: "flex",
      flexDirection: "column",
      width: "48%",
    },
    galleryItemTitle: {
      fontSize: "1rem",
      marginBottom: "0.5rem",
    },
    contactItem: {
      margin: "0.8rem auto 0.6rem",
      display: "flex",
      alignItems: "center",
      textDecoration: "none",
    },
    contactItemIcon: {
      paddingRight: "5px",
      color: "black",
    },
    contactItemContent: {
      color: "rgb(0, 0, 238)",
    },
    servicesWrapper: {
      marginBottom: "2.5rem",
    },
    itemsList: {
      listStyleType: "disclosure-closed",
      "& li": {
        margin: "0.2rem 0rem",
      },
    },
    switchRowColumn: {},
    "@media (min-width: 600px)": {
      outer: {
        padding: "0px 50px",
        margin: "1rem 0rem 0rem",
      },
      servicesWrapper: {
        marginBottom: "3.5rem",
      },
      galleryItem: {
        padding: "0.5rem 0.41%",
        boxSizing: "border-box",
        width: "33.33%",
      },
      galleryItemTitle: {
        fontSize: "1.2rem",
        marginBottom: "0.5rem",
      },
    },
    "@media (min-width: 900px)": {
      switchRowColumn: {
        display: "flex",
        justifyContent: "space-evenly",
      },
    },
  };
});

const Content = () => {
  const classes = useStyles();

  return (
    <Grid container className={classes.outer}>
      <Grid item xs={false} md={1} />
      <Grid item xs={12} md={10} style={{ textAlign: "center" }}>
        <div style={{ marginTop: "2rem", marginBottom: "3rem" }}>
          <div
            style={{
              fontSize: "1.5rem",
              marginBottom: "1rem",
              fontWeight: "bold",
            }}
          >
            <FormattedMessage id="aboutUs" />
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
            }}
          >
            <div style={{ marginBottom: "1rem", textAlign: "center" }}>
              Imate rješenje o osnivanju trgovačkog društva i klasifikaciji
              djelatnosti te ste otvorili transakcijski račun u poslovnoj banci?
              Prijavu početka poslovanja Poreznoj upravi prepustite nama.
            </div>
            <div style={{ marginBottom: "1rem", textAlign: "center" }}>
              Vaš poslovni uspjeh nam je važan i zato je pružanje naših usluga
              prilagođeno potrebama svakog pojedinog klijenta.
            </div>
            <div style={{ marginBottom: "1rem", textAlign: "center" }}>
              Ako ste u nama prepoznali partnera kojem ćete se obratiti za{" "}
              <b>savjetovanje u vezi s poslovanjem</b> ili povjeriti{" "}
              <b>računovodstvo</b>, za Vašu tvrtku možemo obaviti usluge
              navedene u nastavku.
            </div>
          </div>
        </div>
      </Grid>

      <Grid item xs={12} md={12} style={{ textAlign: "center" }}>
        <div style={{ marginBottom: "2rem" }}>
          <div
            style={{
              fontSize: "1.5rem",
              marginBottom: "1rem",
              fontWeight: "bold",
            }}
          >
            <FormattedMessage id="contact" />
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
            }}
          >
            <a
              href="tel:+385921372595"
              className={classes.contactItem}
              style={{ display: "flex", alignItems: "center" }}
            >
              <PhoneIcon className={classes.contactItemIcon} />
              <span className={classes.contactItemContent}>092 137 2595</span>
            </a>
            <a
              href="mailto:financije.hr@gmail.com"
              className={classes.contactItem}
            >
              <MailOutlineIcon className={classes.contactItemIcon} />
              <span className={classes.contactItemContent}>
                financije.hr@gmail.com
              </span>
            </a>
            {/* <a
                            href='https://www.google.com/maps/place/Ul.+dr.+Ive+Brodarca+23,+44000,+Sisak/@45.6287948,16.0257098,10z'
                            target="_blank"
                            rel="noopener noreferrer"
                            className={classes.contactItem}
                        >
                            <LocationOnIcon className={classes.contactItemIcon} />
                            <span className={classes.contactItemContent}>Dr. Ive Brodarca 23, Sisak</span>
                        </a> */}
            {/* <div
                            className={classes.contactItem}
                        >
                            <LocationOnIcon className={classes.contactItemIcon} />
                            <span className={classes.contactItemContent} style={{color: 'rgb(0,0,40)'}}>područje Zagreba, Zagrebačke županije, Siska</span>
                        </div> */}
          </div>
        </div>
      </Grid>

      <Grid
        item
        xs={12}
        md={12}
        style={{
          textAlign: "left",
          border: "1px dashed black",
          marginBottom: "2rem",
        }}
      >
        <h3 style={{ textAlign: "center" }}>
          Primopredaja Vaše dokumentacije moguća je:
        </h3>
        <ul className={classes.switchRowColumn}>
          <li>Na Vašoj lokaciji</li>
          <li>Dostavom putem interneta</li>
          <li>Osobnom dostavom</li>
        </ul>
      </Grid>

      <Grid container className={classes.servicesWrapper} xs={12}>
        <Grid xs={12} md={12}>
          <h2 style={{ marginBottom: "1rem", textAlign: "center" }}>
            <FormattedMessage id="services" />
          </h2>
        </Grid>
        <Grid xs={12} md={6}>
          <h3 style={{ textAlign: "center" }}>
            Vođenje poslovnih knjiga na temelju primljenih knjigovodstvenih
            isprava
          </h3>
          <ul className={classes.itemsList}>
            <li>dnevnik</li>
            <li>glavna knjiga</li>
            <li>blagajnički dnevnik</li>
            <li>knjiga ulaznih (URA) i izlaznih (IRA) računa</li>
            <li>
              analitičko knjigovodstvo opreme (materijalne i nematerijalne
              imovine)
            </li>
            <li>saldakonti kupaca (IOS)</li>
          </ul>
        </Grid>
        <Grid xs={12} md={6}>
          <h3 style={{ textAlign: "center" }}>
            Sastavljanje temeljnih financijskih
            <br />
            izvještaja
          </h3>
          <ul className={classes.itemsList}>
            <li>godišnja bilanca</li>
            <li>godišnji račun dobiti i gubitka (RDG)</li>
            <li>godišnji statistički izvještaj (GFI-pod)</li>
          </ul>
        </Grid>
        <Grid xs={12} md={6}>
          <h3 style={{ textAlign: "center" }}>Obračun plaće</h3>
          <ul className={classes.itemsList}>
            <li>obračun plaće</li>
            <li>prijava/odjava radnika</li>
            <li>evidencije</li>
          </ul>
        </Grid>
        <Grid xs={12} md={6}>
          <h3 style={{ textAlign: "center" }}>Ostale usluge</h3>
          <ul className={classes.itemsList}>
            <li>slanje obrazaca PDV, eURA, PDV-S, ZP itd.</li>
            <li>GDPR</li>
            <li>ostalo po dogovoru</li>
          </ul>
        </Grid>
      </Grid>

      <Grid item xs={12} md={12} style={{ textAlign: "center" }}>
        <div style={{ marginTop: "0rem", marginBottom: "2rem" }}>
          <div
            style={{
              fontSize: "1.5rem",
              marginBottom: "1rem",
              fontWeight: "bold",
            }}
          >
            <FormattedMessage id="price" />
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
            }}
          >
            <div style={{ marginBottom: "1rem", textAlign: "center" }}>
              {`Cijena usluga utvrđuje se za svakog klijenta individualno.`}
            </div>
            <div style={{ marginBottom: "1rem", textAlign: "center" }}>
              {` Cijena ovisi o broju zaposlenika, broju knjigovodstvenih isprava koje nastaju u Vašem poslovanju te složenosti potrebnih knjigovodstvenih i računovodstvenih usluga koje zahtjeva Vaše poslovanje.`}
            </div>
            <div style={{ marginBottom: "1rem", textAlign: "center" }}>
              {`Pomoć oko jednostavnijih pitanja vezanih uz poreze, računovodstvo i financije ne naplaćuje se ukoliko je potpisan temeljni ugovor o suradnji.`}
            </div>
            <div style={{ marginBottom: "1rem", textAlign: "center" }}>
              {`Obratite nam se radi izračuna i ponude!`}
            </div>
          </div>
        </div>
      </Grid>
    </Grid>
  );
};

export default Content;
