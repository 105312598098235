const translations = {
  hr: {
    imageSwitcher1: "Vaš poslovni uspjeh",
    imageSwitcher2: "nam je važan",
    aboutUs: "O nama",
    aboutUs1:
      "Imate rješenje o osnivanju trgovačkog društva i klasifikaciji djelatnosti te ste otvorili transakcijski račun u poslovnoj banci? Prijavu početka poslovanja Poreznoj upravi prepustite nama.",
    contact: "Kontakt",
    services: "Usluge",
    price: "Cijena",
  },
  en: {
    imageSwitcher1: "Your business success",
    imageSwitcher2: "is what matters",
    aboutUs: "About us",
    aboutUs1:
      "Imate rješenje o osnivanju trgovačkog društva i klasifikaciji djelatnosti te ste otvorili transakcijski račun u poslovnoj banci? Prijavu početka poslovanja Poreznoj upravi prepustite nama.",
    contact: "Contact",
    services: "Services",
    price: "Price",
  },
  fr: {
    imageSwitcher1: "",
    imageSwitcher2: "",
    aboutUs: "",
    aboutUs1: "",
    contact: "",
    services: "",
    price: "",
  },
  de: {
    imageSwitcher1: "",
    imageSwitcher2: "",
    aboutUs: "",
    aboutUs1: "",
    contact: "",
    services: "",
    price: "",
  },
};

type Language = keyof typeof translations;

export { translations };
export type { Language };
