import { useEffect, useState, } from 'react';
import TitleRow from './TitleRow';
import { makeStyles } from '@mui/styles';
import { useLocation } from 'react-router-dom';
import Content from './Content';
import ImageSwitcher from './ImageSwitcher';
import { IntlProvider } from 'react-intl';
import { Language, translations } from '../../translations';

const useStyles = makeStyles(theme => {
  return ({
    outer: {
      minHeight: '100%',
      position: 'absolute',
      width: '100vw',
      backgroundColor: '#F5F5F5',
    },
    //[theme.breakpoints.up('sm')/* '@media (min-width: 600px)' */]: {
    //},
  });
});


const Home = () => {
  const classes = useStyles();
  let location = useLocation();
  const [locale, setLocale] = useState<Language>('hr');

  useEffect(() => {
    /* console.log('scroll to top:', document.getElementsByTagName('body')[0]); */
    document.getElementsByTagName('html')[0].scrollTop = 0;
  }, [location]);

  return (
    <div className={classes.outer}>
      <IntlProvider messages={translations[locale]} locale={locale}>
        <TitleRow locale={locale} setLocale={setLocale} />

        <ImageSwitcher />

        <div style={{
          display: 'flex',
          alignItems: 'center',
          width: '95%',
          maxWidth: '950px',
          margin: 'auto',
          flexDirection: 'column',
        }}>
          <Content />
        </div>
        
        <div style={{ width: '100%', backgroundColor: '#080814', color: 'white', fontSize: '0.9rem', textAlign: 'center', padding: '3px', boxSizing: 'border-box', }}>
          &copy; e.FAKTI, 2020-{new Date().toLocaleDateString('sv-SE', { timeZone: 'Europe/Zagreb' }).substring(0,4)}
        </div>
      </IntlProvider>
    </div>
  );
};

export default Home;
